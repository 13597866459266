import React, { useState, useEffect, useContext } from "react";
//ESTILO CSS
import "./digital-credential.scss";
//HOOKS PERSONALIZADOS
import { useToggle } from "../../../hooks/useToggle";
import { InformacionComponent as InformacionCredencial } from "./informacion";
import { FaUserCircle } from "react-icons/fa";
import axios from "axios";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import { FetchFunction } from "../../../hooks/fetchFunction";
import Swal from "sweetalert2";
import { Link, useSearchParams } from "react-router-dom";
import { passportValidation } from "../../../utils/hooks/regex";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

//IMAGENES
import ClassicCardImage from "../../../assets/img/cards/classic.png";
import PremiumCardImage from "../../../assets/img/cards/premium.png";
import DownloadIcon from "../../../assets/img/descargar.png";
import WhatsappIcon from "../../../assets/img/whatsapp-blue.png";
import MailIcon from "../../../assets/img/mail-activo.png";
import InformationIcon from "../../../assets/img/information.svg";
import imgAvatar from "../../../assets/img/hb/britanico.png";
import CommonLoader from "../../common/loader";
import { myAxios } from "../../../utils/fetch";
import { useLocation } from "react-router-dom";

const SECTIONS = ["INFO", "CARD", "COMPLETEIT"];

const DigitalCredentialComponent = (props) => {
  const {
    openHelper,
    sitePSHB,
    setSitePSHB,
    userPSHB,
    setUserPSHB,
    user,
    familiarGroupToken,
    setFamiliarGroupToken,
  } = useContext(UserGlobalContextMemorySpace);
  const [section, setSection] = useState(SECTIONS[0]);
  const [userSelected, setUser] = useState();
  const [loader, setLoader] = useState(true);
  const [credentialBase, setCredentialBase] = useState(null);
  const [credentialLoader, setCredentialLoader] = useState(false);
  const [credentialSelected, setCredentialSelected] = useState("");
  const [familiarGroupComponent, setFamiliarGroupComponent] = useState(null);
  const [appToken, setAppToken] = useState("");
  const location = useLocation();

  useEffect(() => {
    //Mount:

    let body = {
      dnitype: user.user.data.Customers.dniType,
      dni: user.user.data.Customers.dni,
    };

    axios({
      url: `${process.env.REACT_APP_GATEWAY_QA}/customers/api/v1/getfamiliargroup`,
      method: "POST",
      data: body,
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    })
      .then((response) => {
        if (response.data.data.FamiliarGroup) {
          setFamiliarGroupComponent(response.data.data.FamiliarGroup);
        }
        setFamiliarGroupToken(response.data.appToken);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });

      selectUser(
        user,
        user.user.data.Customers.socialSecurityNumber
          .split(" ")
          .join("")
      )

    return () => {
      //Unmount
    };
  }, []);

  const getCredential = async (credential) => {
    try {
      const { data } = await myAxios({
        method: "GET",
        url:
        process.env.REACT_APP_GATEWAY_QA +
        process.env.REACT_APP_GATEWAY_ENDPOINT_GETCREDENTIALBYDNI +
        "?Code=" +
        credential,
        headers: { Authorization: "Bearer " + user.user.data.appToken },
      });
      setCredentialSelected(data.data.CredentialCode);
      return data;
    } catch (err) {
      return err;
    }
  };

  const logCMS = async (data) => {
    let body = {
      user: data,
      type: "WEB",
      feature: "CREDENTIAL",
    };

    try {
      let data = await axios({
        url:
          process.env.REACT_APP_GATEWAY_QA +
          process.env.REACT_APP_GATEWAY_ENDPOINT_LOGCMS,
        method: "POST",
        headers: { Authorization: "Bearer " + user.user.data.appToken },
        data: body,
      });
  
      return data;
    } catch (error) {}
  };

  const selectUser = async (user, credential) => {
    setCredentialBase(null);
    setCredentialLoader(true);
    setSection(SECTIONS[1]);
    setUser(user);
    let data = await getCredential(user?.dni || user?.user?.data?.Customers?.dni);
    if (data.responseCode == 200 && data?.data?.Credential) {
      logCMS(data.data.Credential);
      setCredentialBase(data.data.Credential);
    }
    console.log("fin de carga")
    setCredentialLoader(false);
  };

  return (
    <section
      className="container"
      style={{ maxWidth: "100%", paddingRight: "0", scrollPaddingLeft: "0" }}
    >
      <div className="p-5 padding-digital-credential">
        <div className="row row-digital-credential">
          <div className="col-md-8">
            <HeaderLeft visible={openHelper} />
            {section === SECTIONS[0] ? (
              <InfoCredential setSection={setSection} />
            ) : section === SECTIONS[1] ? (
              <CardCredential
                credentialLoader={credentialLoader}
                credential={credentialBase}
                userSelected={userSelected}
                setSection={setSection}
              />
            ) : (
              <CompleteItCredential
                familiarGroupToken={familiarGroupToken}
                user={user}
                credentialSelected={credentialSelected}
                setSection={setSection}
              />
            )}
          </div>
          <div className="col-md-4 ps-lg-5 pe-5 pb-5">
            {/* <SectionUsers setUser={setUser} userSelected={userSelected} setSection={setSection}/> */}

            <div className="header-digital-credential">
              <div className="row">
                <div className="col-lg-12 header-digital-credential-title">
                  <h2 className="section_pshb_title_2">
                    <b>Credenciales del grupo familiar</b>
                  </h2>
                </div>
              </div>
              {/* <div className='integrante-grupo-familiar mt-3' onClick={()=>selectUser(userList[0])}>
                                <FaUserCircle size='20px' style={{color: "green", marginBottom: "5px"}}/>
                                <span className={userSelected===userList[0]?"userSelected":""}>{userList[0].name}</span>                        
                            </div> */}
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div class="separador-table-green-result-slim"></div>
              </div>

              {loader ? (
                <>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100px", marginTop: "50px" }}
                  >
                    <div
                      style={{
                        width: "1rem",
                        height: "1rem",
                        color: "#5d84aa",
                      }}
                      className="spinner-grow text-muted"
                    ></div>
                    <div
                      style={{
                        marginLeft: "5px",
                        width: "1rem",
                        height: "1rem",
                        color: "#5d84aa",
                      }}
                      className="spinner-grow text-muted"
                    ></div>
                    <div
                      style={{
                        marginLeft: "5px",
                        width: "1rem",
                        height: "1rem",
                        color: "#5d84aa",
                      }}
                      className="spinner-grow text-muted"
                    ></div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="col-lg-12"
                    onClick={() =>
                      selectUser(
                        user,
                        user.user.data.Customers.socialSecurityNumber
                          .split(" ")
                          .join("")
                      )
                    }
                  >
                    <div className="integrante-grupo-familiar">
                      {/* <img src={imgAvatar} alt="..." class="img-circle" /> */}
                      <FaUserCircle
                        style={{
                          color: "green",
                          marginBottom: "5px",
                          width: "20px !important",
                        }}
                      />
                      <span
                        className={userSelected === user ? "userSelected" : ""}
                      >
                        {user.user.data.Customers.firstName}{" "}
                        {user.user.data.Customers.lastName}
                      </span>
                    </div>
                    <div class="separador-table-green-result-slim"></div>
                  </div>

                  {familiarGroupComponent &&
                  Array.isArray(familiarGroupComponent) ? (
                    <>
                      {familiarGroupComponent.map((user, index) => {
                        return (
                          <div
                            className="col-lg-12"
                            key={index}
                            onClick={() =>
                              selectUser(
                                user,
                                user.credential.split(" ").join("")
                              )
                            }
                          >
                            {user.pshbActive ? (
                              <>
                                <div className="integrante-grupo-familiar">
                                  {/* <img src={imgAvatar} alt="..." class="img-circle" /> */}
                                  <FaUserCircle
                                    style={{
                                      color: "green",
                                      marginBottom: "5px",
                                      width: "20px !important",
                                    }}
                                  />
                                  <span
                                    className={
                                      userSelected === user
                                        ? "userSelected"
                                        : ""
                                    }
                                  >
                                    {index == 0
                                      ? user.firstName + " " + user.lastName
                                      : user.fullName}
                                  </span>
                                </div>
                                <div class="separador-table-green-result-slim"></div>
                              </>
                            ) : null}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>{/* MENSAJE FALLA EN EL SERVICIO */}</>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const HeaderLeft = (props) => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const openDialogParams = searchParams.get("open");
  const infoToggle = useToggle(props.visible);
  const credencialToggle = useToggle();

  useEffect(() => {
    if (openDialogParams == "1") {
      infoToggle.onToggle();
    }
  }, []);

  return (
    <>
      <div className="header-digital-credential">
        <div className="row">
          <div className="col-lg-6">
            <h2 className="section_pshb_title_1 text-credencial-digital">
              <b>Mi credencial digital</b>
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12" id="header-invoice">
            <span className="section_pshb_subtitle">
              Podés utilizar la credencial para realizar trámites on-line y
              presenciales. Tiene la misma validez que el carnet impreso.
            </span>
            <a
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Ayuda"
              className="tooltip-help-credential"
            >
              <ReactTooltip id="my-tooltip" />{" "}
              <img
                id="icon-information"
                src={InformationIcon}
                alt="..."
                data-tip="Texto del tooltip"
                style={{ height: "28px" }}
                onClick={() => credencialToggle.onToggle()}
              />
            </a>
            <InformacionCredencial
              key={Math.random()}
              visible={credencialToggle.toggle}
              onToggle={credencialToggle.onToggle}
            />
          </div>
        </div>
      </div>

      <div className="separador-table-green-result  mt-3 mb-4"></div>
    </>
  );
};

const SectionUsers = ({
  setSection,
  setUser,
  userSelected,
  familiarGroupComponent,
}) => {
  const selectUser = (user) => {
    setSection(SECTIONS[1]);
    setUser(user);
  };

  return (
    <>
      <div className="header-digital-credential">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="section_pshb_title_1">
              <b>Credenciales del grupo familiar</b>
            </h2>
          </div>
        </div>
        {/* <div className='integrante-grupo-familiar mt-3' onClick={()=>selectUser(userList[0])}>
                    <FaUserCircle size='20px' style={{color: "green", marginBottom: "5px"}}/>
                    <span className={userSelected===userList[0]?"userSelected":""}>{userList[0].name}</span>                        
                </div> */}
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div class="separador-table-green-result-slim"></div>
        </div>

        {familiarGroupComponent.map((user, index) => {
          return (
            <div
              className="col-lg-12"
              key={index}
              onClick={() => selectUser(user)}
            >
              <div className="integrante-grupo-familiar">
                {/* <img src={imgAvatar} alt="..." class="img-circle" /> */}
                <FaUserCircle
                  size="20px"
                  style={{ color: "green", marginBottom: "5px" }}
                />
                <span className={userSelected === user ? "userSelected" : ""}>
                  {index == 0
                    ? user.firstName + " " + user.lastName
                    : user.fullName}
                </span>
              </div>
              <div class="separador-table-green-result-slim"></div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const InfoCredential = ({ setSection }) => {
  return (
    <div className="card card-refund b-radius-20 shadow-external me-5 ms-5 mt-3">
      <div className="g-0 m-auto">
        <div
          style={{ width: "250px" }}
          className="text-center d-flex flex-column"
        >
          <span className="mt-4 section_pshb_subtitle">
            Seleccioná el miembro del grupo familiar para compartir su
            credencial digital vía e-mail o WhatsApp.
          </span>
        </div>
      </div>
    </div>
  );
};

const CardCredential = ({
  userSelected,
  setSection,
  credential,
  credentialLoader,
}) => {
  const [credentialError, setCredentialerror] = useState(false);

  useEffect(() => {
    //Mount:
    setCredentialerror(false);
    return () => {
      //Unmount
    };
  }, [credential]);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <div className='card-type' id={"card-"+userSelected.card_type}>
                    <span className='card-number'>{userSelected.card_number}</span>
                    <span className='card-user-name'>{userSelected.name}</span>
                    <div className='card-separator'></div>
                    <span className='card-desde'>Desde {userSelected.since}</span>
                    <span className='card-hasta'>Hasta {userSelected.until}</span>
                </div>    */}
        {credentialLoader ? (
          <>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "295px" }}
            >
              <div
                style={{ width: "1rem", height: "1rem", color: "#5d84aa" }}
                className="spinner-grow text-muted"
              ></div>
              <div
                style={{
                  marginLeft: "5px",
                  width: "1rem",
                  height: "1rem",
                  color: "#5d84aa",
                }}
                className="spinner-grow text-muted"
              ></div>
              <div
                style={{
                  marginLeft: "5px",
                  width: "1rem",
                  height: "1rem",
                  color: "#5d84aa",
                }}
                className="spinner-grow text-muted"
              ></div>
            </div>
          </>
        ) : (
          <>
            {credential && !credentialError ? (
              <>
                <div className="card-type d-flex justify-content-center">
                  <img
                    src={`data:image/jpeg;base64,${credential}`}
                    width="380px"
                    onError={() => setCredentialerror(true)}
                  />
                </div>
                <button
                  onClick={() => setSection(SECTIONS[2])}
                  class="btn btn-hb-primary mt-4 btn-pshb"
                >
                  Compartir credencial
                </button>
              </>
            ) : !credential || credentialError ? (
              <>
                <div className="card-type d-flex justify-content-center">
                  <p>
                    No se encontró la credencial, por favor intente nuevamente
                    en unos minutos.
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="card-type d-flex justify-content-center">
                  <p>
                    No se encontró la credencial, por favor intente nuevamente
                    en unos minutos.
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

const CompleteItCredential = ({
  setSection,
  credentialSelected,
  user,
  familiarGroupToken,
}) => {
  const [docType, setDocType] = useState("");
  const [documento, setDocumento] = useState("");
  const [loader, setLoader] = useState(false);
  const [showShareButton, setShowShareButton] = useState(false);
  const [sharedCredential, setSharedCredential] = useState("");
  const [sharedCredentialURL, setSharedCredentialURL] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const getCredentialURL = async (body) => {
    try {
      let { data } = await myAxios({
        method: "PUT",
        url:
          process.env.REACT_APP_GATEWAY_QA +
          process.env.REACT_APP_GATEWAY_ENDPOINT_SHARECREDENTIAL,
        headers: { Authorization: "Bearer " + familiarGroupToken },
        data: body,
      });
      return data;
    } catch (err) {
      setErrorMsg(
        "* Detectamos un inconveniente en el intento de compartir la credencial, por favor intenta nuevamente en breves minutos."
      );
    }
  };

  const handleShareCredential = async (event) => {
    event.preventDefault();
    setErrorMsg("");
    if (docType != "" && documento != "") {
      setLoader(true);
      const body = {
        credential: credentialSelected,
        idcustomer: user.user.data.Customers.idCustomer,
        document: documento,
        documenttype: docType,
        accesToken: "s",
      };

      let response = await getCredentialURL(body);
      setLoader(false);
      if (
        response.data.SharedCredential.hbourl &&
        response.data.SharedCredential.token
      ) {
        setSharedCredential(response.data.SharedCredential.hbourl);
        //setSharedCredentialURL(`https://web.whatsapp.com/send?abid=phonenumber&text=Hola%20te%20comparto%20mi%20credencial%20del%20Plan%20de%20Salud%20${response.data.SharedCredential.hbourl}`);
        // setSharedCredentialURL(`whatsapp://send?text=Hola%20te%20comparto%20mi%20credencial%20del%20Plan%20de%20Salud%20${response.data.SharedCredential.hbourl}`);
        setSharedCredentialURL(
          `https://wa.me/?text=Hola%20te%20comparto%20mi%20credencial%20del%20Plan%20de%20Salud%20${response.data.SharedCredential.hbourl}`
        );
        setShowShareButton(true);
      }
    } else {
      setErrorMsg("* Por favor introduce un tipo y número de documento.");
    }
  };

  const handleOnChangeDocType = (value) => {
    setDocType(value);
    setDocumento("");
  };

  return (
    <div className=" card-refund b-radius-20 shadow-external me-5 ms-5 mt-3">
      <div className="g-0 m-auto">
        <div className="text-center d-flex flex-column text-center justify-content-center align-items-center">
          <span className="mt-4 mb-3 section_pshb_subtitle">
            Completá los datos de la persona que tendrá el permiso para utilizar
            tu credencial:
          </span>

          <div style={{ width: "250px" }}>
            <form onSubmit={handleShareCredential}>
              <select
                onChange={(e) => handleOnChangeDocType(e.target.value)}
                className={`option-register-credential shadow-blue`}
                style={{ width: "100%" }}
              >
                <option
                  style={{
                    textAling: "center !important",
                    textAlignLast: "center !important",
                  }}
                  className=""
                  value=""
                  hidden
                >
                  Tipo de Documento
                </option>
                <option value="1">DNI</option>
                <option value="2">Pasaporte</option>
              </select>

              <input
                maxLength={docType == "2" ? 20 : 8}
                onChange={(e) =>
                  docType == "2"
                    ? setDocumento(passportValidation(e.target.value))
                    : setDocumento(e.target.value.replace(/[^0-9]/g, ""))
                }
                className={`input-register shadow-blue`}
                placeholder="N° de Documento"
                style={{
                  width: "100%",
                  marginTop: "30px",
                  marginBottom: "30px",
                  textAlign: "center",
                }}
                value={documento}
              ></input>

              {loader ? (
                <>
                  <button
                    style={{ width: "50%" }}
                    disabled={true}
                    type="submit"
                    className="button-loader shadow-blue"
                  >
                    <span className="spinner-grow spinner-grow-sm"></span>
                    <span className="spinner-grow spinner-grow-sm"></span>
                    <span className="spinner-grow spinner-grow-sm"></span>
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    style={{ width: "50%" }}
                    className="button-loader shadow-blue"
                  >
                    Continuar
                  </button>
                  <br />
                  <br />
                  <p style={{ color: "red", fontWeight: "500" }}>{errorMsg}</p>
                </>
              )}
            </form>
            {showShareButton ? (
              <>
                <div
                  className="d-flex align-items-center justify-content-around"
                  style={{ marginTop: "30px" }}
                >
                  <div>
                    <h6>Compartir:</h6>
                  </div>
                  <div className="share-container-credential">
                    <div>
                      <a
                        href={`mailto:${""}?subject=${"Te comparto mi credencial digital del Plan de Salud del Hospital Británico"}&body=${
                          "Hola te comparto mi credencial del Plan de Salud " +
                          sharedCredential
                        }`}
                      >
                        <img style={{ width: "35px" }} src={MailIcon} alt="" />
                      </a>
                    </div>
                    {/* <button onClick={()=>console.log(sharedCredentialURL)}>test</button> */}
                    <div
                      onClick={() => window.open(sharedCredentialURL, "_blank")}
                    >
                      <img
                        style={{ width: "35px" }}
                        src={WhatsappIcon}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalCredentialComponent;
